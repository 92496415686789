import { translation } from '@jotforminc/translation';
import { getFullLangCode } from '../api';

/**
* Initialize the language for non english enterprises.
* @param {string} localeCode ISO language code and
* country code combined to create a locale code.
* @returns true if successful, false otherwise.
*/
const initLocalization = async localeCode => {
  try {
    // Normalize regional locale codes (eg: fr-CA, fr-CH) to one
    // Jotform has its translations to (eg: fr-FR).
    const languageCode = localeCode.split('-')[0];
    const normalizedLocaleCode = await getFullLangCode(languageCode);
    // If we can't, just try the user provided one.
    const fullLocaleCode = normalizedLocaleCode?.responseCode === 200
      ? normalizedLocaleCode.content
      : localeCode;
    global.window.currentLocale = fullLocaleCode;
    await translation.addDictionary(fullLocaleCode);
    translation.setLocale(fullLocaleCode);
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
};

/**
 * Check the browser language and if not english,
 * enable localization.
 */
export const checkAndInitLocalization = async () => {
  const browserLanguage = global.navigator?.language || 'en-US';
  if (!browserLanguage.includes('en')) {
    // Don't load if english since dictionaries are big
    await initLocalization(browserLanguage);
  }
};
